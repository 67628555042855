import React, { useCallback, useEffect, useState } from 'react';
import useUserProfile from '../../../hooks/use-user-profile';
import { ActivityIndicator } from '../../../components/common/activity-indicator/activity-indicator';
import HttpService from '../../../services/http-service/http-service';
import yfaFMBackGround from '../../../assets/yfa/FMBackground.png';
import { RouteProps, useHistory, useLocation } from 'react-router-dom';
import './yfa-opt-in-view.scss';
import { Modal } from '../../../components/common/modal/modal';
import {
    Checkbox,
    InputField,
    PrimaryButton,
    SecondaryButton,
} from '../../../components/common';
import { useAnalytics } from '../../../hooks/use-analytics';
import { TermsAndConditionsService } from '../../../components/sections/terms-and-conditions-modal/services/terms-and-conditions-service/terms-and-conditions-service';
import { DSL_API_PATH, DSL_BASE_URL } from '../../../constants';
import YfaService, {
    EnrollWithVINRequest,
    ManagePrivacyRequest,
    UserReferral,
    UserSelections,
} from '../../../services/yfa-service/yfa-service';
import { StringUtil } from '../../../components/utils/string-util/string-util';

import { VehicleDetail } from '../../../models/profile-with-vehicles';
import AuthenticationService from '../../../services/authentication-service/authentication-service';

interface OrderView {
    ecommOrderId: string;
    id: string;
    vin: string;
    phoneNumber: string;
    enrolled: boolean;
}

interface Orders {
    result?: {
        orders: OrderView[];
    };
}

interface YfaVehicle {
    vin: string;
    enrolled: boolean;
}
const getUpdatedPhoneNumber = (
    updatedPhoneNumber: string,
    customerPhoneNumber: string
) =>
    updatedPhoneNumber
        ? updatedPhoneNumber?.replaceAll('-', '')
        : customerPhoneNumber?.replaceAll('-', '');

const getRouteState = (
    props: RouteProps<string, { [x: string]: string | undefined }>
): any => {
    return props.location?.state || {};
};

export const YFAOptInView = (props: RouteProps) => {
    const correctPhoneNumberFormat = /^[2-9]\d{2}-\d{3}-\d{4}$/;
    const [orders, setOrders] = useState<OrderView[]>();
    const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
    const [garageSelectedVins, setGarageSelectedVins] = useState<string[]>([]);
    const [terms, setTerms] = useState<string>();
    const [consentsAccepted, setConsentsAccepted] = useState<boolean>();
    const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPhoneInput, setShowPhoneInput] = useState(true);
    const [userSelection, setUserSelection] = useState<UserSelections>({
        email: false,
        fordWebsite: false,
        socialMedia: false,
        other: false,
    });
    const [otherInput, setOtherInput] = useState<string>('');

    const authenticationService = new AuthenticationService();
    const referrer = new URLSearchParams(useLocation()?.search)?.get(
        'referrer'
    );
    const referredByEmail = referrer === 'welcome-email';
    const referredByDynamicManage = referrer === 'dynamic-manage';
    const referredByShopOwner = referrer === 'shop-owner';
    const profile = useUserProfile();
    // test; comment later
    // if (profile && profile.profile) {
    //     profile.profile.phoneNumber = '';
    //     profile.profile.mobilePhoneNumber = '';
    // }
    useAnalytics('yfa-landing');
    const [fireEvents] = useAnalytics();
    const {
        firstName,
        lastName,
        email: customerEmail,
        phoneNumber,
        mobilePhoneNumber,
    } = profile?.profile ?? {};
    const name = `${firstName} ${lastName}`.trim();
    const routeState = getRouteState(props);

    const vin = routeState.vin;
    const isReferrerDashboard = !!vin;
    const referredByEvSupport =
        (!referrer && !isReferrerDashboard) || referrer === 'ev-support';
    const [customerPhoneNumber, setCustomerPhoneNumber] = useState('');
    const history = useHistory();
    const [yfaTAndCDialogState, setYfaTAndCDialogState] = useState(false);
    const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState(
        false
    );
    const isReferred =
        referredByEmail ||
        referredByDynamicManage ||
        referredByShopOwner ||
        referredByEvSupport;
    const [
        termsConditionErrorMessage,
        setTermsConditionErrorMessage,
    ] = useState(false);
    const [termsConditionChecked, setTermsConditionchecked] = useState(false);
    const alternatePhoneNumberLabel = 'Alternative Phone Number (optional)';
    const phoneNumberLabel = 'Phone Number (required)';
    const termsService = new TermsAndConditionsService();
    const yfaService = new YfaService();
    const [garageVehicles, setGarageVehicles] = useState<YfaVehicle[]>([]);
    const isDashboardOrOrdersOrVehicles =
        isReferrerDashboard || orders || garageVehicles;
    const yfaError = useCallback(() => {
        history.push('/yfa-error', {
            yfaErrorNavigationFlag: true,
        });
    }, [history]);

    const yfaEnrolled = useCallback(() => {
        history.push('/yfa-enrolled', {
            yfaEnrolledNavigationFlag: true,
        });
    }, [history]);

    const yfaInvited = useCallback(() => {
        history.push('/yfa-invited', {
            yfaInvitedNavigationFlag: true,
        });
    }, [history]);

    const yfaSuccess = useCallback(() => {
        history.push('/yfa-success', {
            yfaConfirmationNavigationFlag: true,
        });
    }, [history]);

    const profileVehicles = useCallback(() => {
        return profile?.vehicles
            ?.filter(vehicle => vehicle?.engineType?.toUpperCase() === 'BEV')
            ?.filter(vehicle =>
                ['mustang mach-e', 'f-150 lightning'].includes(
                    vehicle?.modelName?.toLowerCase()
                )
            ) as VehicleDetail[];
    }, [profile?.vehicles]);

    const yfaVehicles = useCallback(() => {
        return profileVehicles()?.map(async vehicle => {
            const { vin } = vehicle;
            return yfaService.isEligible(vin).then(item => {
                return {
                    vin,
                    enrolled: item.enrolled,
                } as YfaVehicle;
            });
        });
    }, [profileVehicles]);

    function handleReferredProfile() {
        setLoading(true);
        const yfavehicles = yfaVehicles();
        Promise.all(yfavehicles)
            .then(vehicles => {
                if (vehicles?.length === 0) {
                    yfaInvited();
                    return;
                }
                const filteredVehicles = vehicles.filter(car => !car.enrolled);
                filteredVehicles?.length === 0 && yfaEnrolled();
                filteredVehicles?.length === 1 &&
                    setGarageSelectedVins([filteredVehicles[0].vin]);
                setGarageVehicles(filteredVehicles);
            })
            .catch(() => yfaError())
            .finally(() => {
                setLoading(false);
            });
    }

    function handleNonReferredProfile() {
        HttpService.get<Orders>(
            `${DSL_BASE_URL + DSL_API_PATH.CONSUMER_YFA}/orders`,
            false,
            {
                headers: HttpService.getAppIdAndAuthTokenRequestHeaders(),
            }
        )
            .then(response => {
                if (!response.data.result?.orders) {
                    yfaInvited();
                    return;
                } else {
                    const orders = response.data.result?.orders;
                    if (orders.length > 1) {
                        const enrolledOrders = orders?.filter(
                            order => order.enrolled
                        );
                        if (enrolledOrders.length > 0) {
                            setCustomerPhoneNumber(
                                enrolledOrders[0].phoneNumber
                            );
                            setShowPhoneInput(false);
                        }
                    } else if (orders[0].enrolled) {
                        yfaEnrolled();
                        return;
                    } else {
                        setSelectedOrders([orders[0].id]);
                    }
                    setOrders(orders);
                }
            })
            .catch(error => {
                console.error(error);
                yfaError();
            });
    }

    function checkIfCustomerAlreadyEnrolledOrInvited() {
        if (profile) {
            if (isReferred) {
                handleReferredProfile();
            } else if (!isReferrerDashboard) {
                handleNonReferredProfile();
            }
            setCustomerPhoneNumber(phoneNumber ?? mobilePhoneNumber ?? '');
        }
    }

    const phoneNoToUse = getUpdatedPhoneNumber(
        updatedPhoneNumber,
        customerPhoneNumber
    );

    useEffect(() => {
        window.scroll(0, 0);
        authenticationService
            .onIsAuthenticated()
            .then((authenticated: boolean) => {
                if (authenticated) {
                    if (
                        selectedOrders.length === 0 ||
                        referredByEmail ||
                        referredByDynamicManage ||
                        referredByEvSupport ||
                        referredByShopOwner
                    ) {
                        checkIfCustomerAlreadyEnrolledOrInvited();
                    }
                    if (!terms) {
                        termsService.getTermsContent('yfa').then(terms => {
                            setTerms(terms.legalLanguages[0].legalLanguageText);
                            setTimeout(() => {
                                document
                                    .querySelectorAll('p.MsoNormal')
                                    .forEach(
                                        (element, index) =>
                                            [0, 1].includes(index) &&
                                            ((element as HTMLParagraphElement).style.textAlign =
                                                'center')
                                    );
                            }, 100);
                        });
                    }
                    if (consentsAccepted === undefined) {
                        yfaService
                            .getConsentEnrollmentStatus()
                            .then(consentEnrollmentStatus => {
                                // test; comment later
                                // consentEnrollmentStatus.isConsented = false;
                                setConsentsAccepted(
                                    consentEnrollmentStatus.isConsented
                                );
                                setTermsConditionchecked(
                                    consentEnrollmentStatus.isConsented
                                );
                            });
                    }
                } else authenticationService.login();
            });
    }, [profile, yfaError, yfaEnrolled, yfaInvited]);

    const enrollWithVinVehicleDetail = (vehicle: VehicleDetail) => {
        if (vehicle) {
            const enrollWithVinRequest = {
                vin: vehicle?.vin,
                email: customerEmail?.toLowerCase(),
                phoneNumber: phoneNoToUse,
                brand: vehicle?.make,
                model: vehicle?.modelName,
                modelYear: vehicle?.modelYear?.toString(),
                referral: buildReferral(),
                referredByEmail,
                referredByDynamicManage,
                referredByShopOwner,
                referredByEvSupport,
            } as EnrollWithVINRequest;

            yfaService
                .enrollWithVin(enrollWithVinRequest)
                .then(() => yfaSuccess())
                .catch(() => yfaError())
                .finally(() => {
                    setLoading(false);
                });
        }
    };
    const createManagePrivacyRequest = () => {
        return {
            brandCode: 'F',
            countryCode: 'USA',
            privacyPreferences: [
                {
                    statusCode: 'Y',
                    devices: [
                        {
                            deviceType: 'email',
                            deviceValue: customerEmail || '',
                        },
                    ],
                    consentName: 'Marketing Via Email',
                    pouId: '1001',
                },
                {
                    statusCode: 'Y',
                    devices: [
                        {
                            deviceType: 'Cellular',
                            deviceValue: phoneNoToUse,
                        },
                    ],
                    consentName:
                        'Opt In to Receive Commercial or Marketing Text Messages',
                    pouId: '1000',
                },
                {
                    statusCode: 'Y',
                    devices: [
                        {
                            deviceType: 'Cellular',
                            deviceValue: phoneNoToUse,
                        },
                    ],
                    consentName:
                        'Opt In to Receive Transactional Text Messages',
                    pouId: '1079',
                },
            ],
        } as ManagePrivacyRequest;
    };

    const handleEnrollment = (managePrivacyResponse: any) => {
        if (!managePrivacyResponse.error) {
            if (isReferrerDashboard) {
                const vehicle = profileVehicles()?.filter(
                    vehicle => vehicle.vin === vin
                )[0];
                vehicle && enrollWithVinVehicleDetail(vehicle);
            } else if (isReferred) {
                garageSelectedVins
                    ?.map(vin => {
                        return profileVehicles()?.filter(
                            vehicle => vehicle?.vin === vin
                        )[0];
                    })
                    .forEach(vehicle => {
                        vehicle && enrollWithVinVehicleDetail(vehicle);
                    });
            } else {
                selectedOrders.forEach((selectedOrder, index) => {
                    const bodyRequest = {
                        orderId: selectedOrder,
                        phoneNo: phoneNoToUse,
                        referral: buildReferral(),
                    };
                    yfaService
                        .enroll(bodyRequest)
                        .then(() => {
                            yfaSuccess();
                        })
                        .catch(() => {
                            yfaError();
                        })
                        .finally(() => {
                            if (index === selectedOrders.length - 1)
                                setLoading(false);
                        });
                });
            }
        } else {
            yfaError();
        }
    };

    const enroll = () => {
        if (
            !phoneNoToUse ||
            (updatedPhoneNumber &&
                !correctPhoneNumberFormat.test(updatedPhoneNumber))
        ) {
            setPhoneNumberErrorMessage(true);
            return;
        }
        if (!termsConditionChecked) {
            setTermsConditionErrorMessage(true);
            return;
        } else {
            const managePrivacyRequest = createManagePrivacyRequest();
            fireEvents('yfa-enroll-btn-click-event');
            setLoading(true);
            yfaService
                .updateEnrollment(managePrivacyRequest)
                .then(handleEnrollment);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const unenroll = (selectedOrder: string) => {
        setLoading(true);
        yfaService
            .unenroll(selectedOrder)
            .then(() => {
                yfaSuccess();
            })
            .catch(() => {
                yfaError();
            })
            .finally(() => setLoading(false));
    };

    const handlePhoneNumberInput = () => {
        const input = document.getElementById(
            'phoneNumberInput-label'
        ) as HTMLInputElement;
        const value = input.value.trim();
        setPhoneNumberErrorMessage(
            value.length > 0 && !correctPhoneNumberFormat.test(value)
        );
        setUpdatedPhoneNumber(value);
    };

    const addHyphensToNumber = (e: { key: any }) => {
        const phoneInput = document.getElementById(
            'phoneNumberInput-label'
        ) as HTMLInputElement;
        if (
            e.key != 'Backspace' &&
            (phoneInput.value.length === 3 || phoneInput.value.length === 7)
        ) {
            phoneInput.value += '-';
        }
    };

    const mapReferralLabel = (choice: string) => {
        if (choice === 'fordWebsite') return 'Ford.com';
        return StringUtil.camelCaseToWords(choice);
    };

    function handleReferralCheckbox(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        setUserSelection({
            ...userSelection,
            [event.target.name]: event.target.checked,
        } as UserSelections);
    }

    const handleOtherInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setOtherInput(inputValue);
        setUserSelection({ ...userSelection, other: inputValue.length > 0 });
    };

    const onChangeVinHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        let vehicles = garageSelectedVins;
        if (event.target.checked) {
            vehicles.push(event.target.value);
        } else {
            vehicles = vehicles.filter(vin => vin !== event.target.value);
        }
        setGarageSelectedVins(Array.from(vehicles));
    };

    const buildReferral = (): UserReferral => {
        if (userSelection.other)
            return {
                ...userSelection,
                description: otherInput,
            };
        return userSelection;
    };
    const isReferredWithGarageSelectedVin =
        isReferred && garageSelectedVins?.length !== 0;

    const hasSelectedVins =
        isReferrerDashboard || isReferredWithGarageSelectedVin;
    const hasSelectedOrders = selectedOrders.length !== 0;
    const hasAcceptedTerms = termsConditionChecked;

    const isButtonDisabled =
        (!hasSelectedVins && !hasSelectedOrders) || !hasAcceptedTerms;
    const isAlternateLabel = Boolean(customerPhoneNumber);
    const changeNumberLabel = isAlternateLabel
        ? alternatePhoneNumberLabel
        : phoneNumberLabel;
    const altNumberMessage = isAlternateLabel
        ? 'If you would like us to text you at a different number, please add it below.'
        : 'Please enter the phone number you would like to use to text with a Ford advisor (or receive phone calls for proactive support):';
    return (
        <>
            {profile && isDashboardOrOrdersOrVehicles ? (
                <div className="yfa-opt-in">
                    <img
                        className="opt-in-background"
                        src={yfaFMBackGround}
                        alt="Welcome Background"
                    />
                    <div className="welcome-text">
                        <div>
                            <h1 className="hello-header">
                                Hello, {firstName}!
                            </h1>
                            <h1 className="welcome-header">
                                Welcome to 24/7 Live Support
                            </h1>
                            <h2 className="welcome-intro-text">
                                Sign up to text a dedicated Ford advisor.
                            </h2>
                            <p className="intro-text">
                                {`24/7 Live Support is part of Ford's commitment to building your confidence
                                  in electric vehicles from day one. With a Ford advisor just a text
                                  away, you'll have the guidance you need as you transition to electric. Plus,
                                  Ford Roadside Assistance now offers proactive charging support and towing, giving
                                  you peace if mind if you ever run low on charge.`}
                            </p>
                            <div>
                                <div className="input-box">
                                    <p>Name</p>
                                    <div>{name}</div>
                                </div>
                                <div className="input-box">
                                    <p>Email</p>
                                    <div>{customerEmail}</div>
                                </div>
                                {orders && orders?.length > 1 && (
                                    <div className="yfa-orders-checkbox">
                                        <p className="ambassador-text">
                                            You have multiple eligible orders.
                                            Please select one or more to
                                            proceed.
                                        </p>
                                        <div className="orders-checkbox">
                                            {orders?.map(order => (
                                                <Checkbox
                                                    name={order.ecommOrderId}
                                                    key={order.ecommOrderId}
                                                    label={
                                                        order.enrolled
                                                            ? `${order.ecommOrderId}(enrolled)`
                                                            : order.ecommOrderId
                                                    }
                                                    ariaLabel={
                                                        order.ecommOrderId
                                                    }
                                                    disabled={order.enrolled}
                                                    onChange={(
                                                        event: React.ChangeEvent<
                                                            HTMLInputElement
                                                        >
                                                    ) => {
                                                        const selectedEcommId =
                                                            event.target.name;
                                                        const selectedOrderId = orders.find(
                                                            order =>
                                                                order.ecommOrderId ===
                                                                selectedEcommId
                                                        )!.id;
                                                        if (
                                                            event.target
                                                                .checked &&
                                                            !selectedOrders.includes(
                                                                selectedOrderId
                                                            )
                                                        ) {
                                                            selectedOrders.push(
                                                                selectedOrderId
                                                            );
                                                        } else if (
                                                            !event.target
                                                                .checked &&
                                                            selectedOrders.includes(
                                                                selectedOrderId
                                                            )
                                                        ) {
                                                            selectedOrders.splice(
                                                                selectedOrders.indexOf(
                                                                    selectedOrderId
                                                                ),
                                                                1
                                                            );
                                                        }
                                                        console.debug(
                                                            selectedOrders
                                                        );
                                                        // set a new array to force re-render
                                                        setSelectedOrders(
                                                            Array.from(
                                                                selectedOrders
                                                            )
                                                        );
                                                    }}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {orders?.length === 1 && (
                                    <div className="input-box">
                                        <p>Order No.</p>
                                        <div>{orders[0].ecommOrderId}</div>
                                    </div>
                                )}
                                {isReferred &&
                                    garageVehicles &&
                                    garageVehicles?.length > 1 && (
                                        <div className="yfa-input-checkbox">
                                            <p className="ambassador-text">
                                                You have multiple eligible
                                                vehicles. Please select one or
                                                more to proceed.
                                            </p>
                                            <div className="input-checkbox">
                                                {garageVehicles?.map(
                                                    vehicle => (
                                                        <Checkbox
                                                            key={vehicle.vin}
                                                            label={vehicle?.vin}
                                                            ariaLabel="vehicle vin"
                                                            name={vehicle.vin}
                                                            value={vehicle.vin}
                                                            onChange={
                                                                onChangeVinHandler
                                                            }
                                                        />
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    )}
                                {garageVehicles?.length === 1 && (
                                    <div className="input-box">
                                        <p>Vehicle VIN</p>
                                        <div>{garageVehicles[0].vin}</div>
                                    </div>
                                )}
                                {isReferrerDashboard && (
                                    <div className="input-box">
                                        <p>Vehicle Vin</p>
                                        <div>{vin}</div>
                                    </div>
                                )}
                                {customerPhoneNumber && (
                                    <div className="input-box">
                                        <p>Phone Number</p>
                                        <div>{customerPhoneNumber}</div>
                                    </div>
                                )}
                                {showPhoneInput && (
                                    <>
                                        <p
                                            id="alternatePhoneNumberMessage"
                                            className="ambassador-text"
                                        >
                                            {altNumberMessage}
                                        </p>
                                        <div className="change-phone-number">
                                            <InputField
                                                type="tel"
                                                className="phone-number-input"
                                                placeHolder="XXX-XXX-XXXX"
                                                value={updatedPhoneNumber}
                                                onChange={
                                                    handlePhoneNumberInput
                                                }
                                                maxLength={12}
                                                onKeyUp={addHyphensToNumber}
                                                label={changeNumberLabel}
                                                name={'phoneNumberInput'}
                                                ariaLabel={'phoneNumberInput'}
                                                errorMessage={
                                                    phoneNumberErrorMessage
                                                        ? 'Incorrect Phone Number Format'
                                                        : undefined
                                                }
                                            />
                                        </div>
                                    </>
                                )}
                                <div className="yfa-input-checkbox">
                                    <p
                                        id="customerInputMessage"
                                        className="ambassador-text"
                                    >
                                        {`How did you hear about Ford's Electric Vehicle 24/7 Live Support? (optional)`}
                                    </p>
                                    <div className="input-checkbox">
                                        {Object.keys(userSelection).map(
                                            choice => (
                                                <div
                                                    key={choice}
                                                    className="input-checkbox-item"
                                                >
                                                    <Checkbox
                                                        name={choice}
                                                        key={choice}
                                                        label={mapReferralLabel(
                                                            choice
                                                        )}
                                                        ariaLabel={choice}
                                                        onChange={
                                                            handleReferralCheckbox
                                                        }
                                                        checked={
                                                            userSelection[
                                                                choice as keyof UserSelections
                                                            ]
                                                        }
                                                    />
                                                    {choice === 'other' && (
                                                        <InputField
                                                            type="text"
                                                            className="other-input"
                                                            placeHolder="Enter text here"
                                                            value={otherInput}
                                                            onChange={
                                                                handleOtherInput
                                                            }
                                                            maxLength={1000}
                                                            name={'otherInput'}
                                                            label=""
                                                            ariaLabel={
                                                                'otherInput'
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="authorize-text">
                            {`By checking the box, you authorize Ford to contact
                            you by text or calls about Ford’s Electric Vehicle
                            24/7 Live Support Service (fka Your Ford
                            Ambassador). Consent is not a condition of purchase.
                            Message frequency varies, data rates apply. Text
                            STOP to stop or HELP for help. See `}
                            <a
                                href="https://www.ford.com/help/privacy/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Notice
                            </a>
                            {` and Terms for details.`}
                        </p>
                    </div>
                    <div className="terms-and-conditions-container">
                        <div className="terms-and-conditions">
                            <Checkbox
                                name={'accept'}
                                label={''}
                                ariaLabel={'accept-terms-and-conditions'}
                                onChange={e =>
                                    setTermsConditionchecked(e.target.checked)
                                }
                                checked={termsConditionChecked}
                                disabled={consentsAccepted}
                            />
                            <SecondaryButton
                                noChevron
                                onClick={() => setYfaTAndCDialogState(true)}
                                type="button"
                            >
                                Accept Terms and Conditions
                            </SecondaryButton>
                        </div>
                        {termsConditionErrorMessage && (
                            <p className="terms-condition-error-message">
                                Please Accept Terms And Conditions to Proceed
                            </p>
                        )}
                        {consentsAccepted && (
                            <p className="terms-condition-consented-message">
                                You have already consented to the program terms
                                in the past
                            </p>
                        )}
                    </div>
                    <div className="button-container">
                        <PrimaryButton
                            className="get-connected-button"
                            color="dark"
                            fill="fill"
                            chevron={true}
                            disabled={isButtonDisabled}
                            onClick={enroll}
                        >
                            Enroll
                        </PrimaryButton>
                        {/* <PrimaryButton
                            className="get-connected-button"
                            color="dark"
                            fill="fill"
                            chevron={true}
                            onClick={unenroll}
                            style={{ display: 'none' }}
                        >
                            Unenroll
                        </PrimaryButton> */}
                        {loading && <ActivityIndicator />}
                    </div>
                </div>
            ) : (
                <ActivityIndicator className="full-height" />
            )}
            <Modal
                name="terms-and-conditions-styling"
                isVisible={yfaTAndCDialogState}
                primaryBtnLabel="Close"
                onPrimaryBtnClick={() => {
                    (document.querySelector(
                        '.modal-close'
                    ) as HTMLButtonElement).click();
                }}
                onClose={() => setYfaTAndCDialogState(false)}
            >
                {terms && (
                    <p
                        className="modal-description"
                        dangerouslySetInnerHTML={{ __html: terms }}
                    ></p>
                )}
            </Modal>
        </>
    );
};

export default YFAOptInView;
